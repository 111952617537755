/*eslint-disable no-shadow */
import React, { Fragment, useEffect } from 'react';
import Pagination from 'components/Pagination';
import get from 'lodash/get';
import { Button, Skeleton } from '@springforcreators/propel-ui';
import ProductTile from 'components/ProductTile';
import ThemeWrapper from 'containers/ThemeWrapper';
import { Waypoint } from 'react-waypoint';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import { useGlobalProps } from 'containers/GlobalPropsContext';
import tracker from 'utils/tracking';
import Slider from 'react-slick';
import { PRODUCT_IMPRESSIONS, pushEvent } from '../../utils/tracking/gtm';
import removeCurrencySymbol from '../../lib';
import { USE_COLLECTIONS } from '../../constants';
import { ProductTileCollections } from '../ProductTileCollections';
import './ProductFeed.scss';
import ProductFeedSkeleton from './ProductFeedSkeleton';
import CustomCollection from '../CustomCollection';
import DropHeading from './DropHeading';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

/* eslint-disable react/jsx-one-expression-per-line */

const ProductFeed = (props) => {
  const {
    storeProducts,
    isFetching,
    storeData,
    fetchProducts,
    themeData
    // getStyles
  } = useGlobalProps();

  const {
    // title,
    localizationData,
    storeName,
    previewMode,
    storeListings
  } = props;

  const { layout, content } = themeData;

  const {
    products,
    page,
    totalPages,
    next
  } = storeProducts;
  const perPage = storeProducts.per_page;
  const showMoreProducts = USE_COLLECTIONS ? page !== totalPages : !!next;
  const { productCacheEnabled } = storeListings;
  const currentPage = page;

  let tiles = [];

  if (products && products.length) {
    tiles = products.map((product, position) => {
      if (USE_COLLECTIONS) {
        return <ProductTileCollections product={ product } key={ product.id } list="Featured Products" position={ position } />;
      } else {
        return <ProductTile product={ product } key={ product.id } list="Featured Products" position={ position } />;
      }
    });
  }

  const handleLoadMore = () => {
    tracker.track(`homepage.load_more.clicked`, { productCount: products.length });
    fetchProducts(currentPage + 1);
  };

  useEffect(() => {
    if (products.length) {
      const latestFetchedProducts = products.slice(products.length - perPage || 0, products.length);
      const productImpressions = latestFetchedProducts.map((product, position) => (
        {
          name: get(product, 'name'),
          id: get(product, 'listingId'),
          price: removeCurrencySymbol(get(product, 'price')),
          brand: storeName,
          category: get(product, 'productName'),
          list: 'Featured Products',
          position: position + 1,
          dimension8: get(storeData, 'sellerId'),
          dimension9: get(product, 'listingId')
        }
      ));
      const eventBody = {
        currencyCode: get(localizationData, 'buyer_currency'),
        impressions: productImpressions
      };
      pushEvent(PRODUCT_IMPRESSIONS, eventBody);
    }
  }, [products]);

  const settings = {
    dots: false,
    fade: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    waitForAnimate: false,
    pauseOnHover: false
  };

  const SldierImages = [
    {
      src: '/assets/bttf/slider/bttf-sliderimg-00.png'
    },
    {
      src: '/assets/bttf/slider/bttf-sliderimg-01.png'
    },
    {
      src: '/assets/bttf/slider/bttf-sliderimg-02.png'
    },
    {
      src: '/assets/bttf/slider/bttf-sliderimg-03.png'
    },
    {
      src: '/assets/bttf/slider/bttf-sliderimg-04.png'
    },
    {
      src: '/assets/bttf/slider/bttf-sliderimg-05.png'
    }
  ];

  return (
    <Fragment>
      <div className="anchor" id="featured-products" />
      {/* hiding standard product feed */}
      <div
        className="product-feed"
        style={ {
          display: 'none'
        } }
      >
        <div className="tile-heading" style={ { display: 'none' } }>
          {isFetching && !products?.length ? (
            <Skeleton styles={ { height: 30, width: '45%' } } />
          ) : (
            <DropHeading title="Combat Club Collection" body={ get(content, 'footer.about.textContent') } />
          )}
        </div>

        { isFetching && !products?.length && (
          <ProductFeedSkeleton />
        ) }

        { (previewMode || get(layout, 'productList.pagination'))
          ? <Pagination items={ tiles } />
          : <div className="product-tile-grid" data-cy="product-tile-grid">{ tiles }</div>
        }

        { showMoreProducts && !productCacheEnabled && (
          <>
            { currentPage < 2 ? (
              <Waypoint
                onEnter={
                  (wp) => {
                    if (!isFetching && wp.previousPosition === 'below' && !get(layout, 'productList.pagination')) {
                      const { page } = storeProducts;
                      fetchProducts(page + 1);
                    }
                  }
                }
              />
            ) : (
              <div className="center-content mb4">
                { !isFetching && (
                  <Button
                    onClick={ handleLoadMore }
                  >
                    Load More
                  </Button>
                )}
              </div>
            )}

          </>
        ) }
      </div>
      {/* Custom collection grid */}
      <div
        style={ {
          maxWidth: '1440px',
          margin: 'auto'
        } }
      >
        <div className="grid-container custom-grid">
          <div className="grid-item grid-span-8 grid-row-span-2">
            {/* Signed Jacket */}
            <CustomCollection products={ products } productIds={ [248433377] } USE_COLLECTIONS={ USE_COLLECTIONS } highResImages={ true } /> {/* US */}
          </div>
          <div className="grid-item grid-span-4">
            {/* Signed doc brown shirt */}
            <CustomCollection products={ products } productIds={ [248433376] } /> {/* US */}
          </div>
          <div className="grid-item grid-span-4">
            {/* Signed poster */}
            <CustomCollection highResImages={ true } products={ products } productIds={ [248559121] } USE_COLLECTIONS={ USE_COLLECTIONS } />
          </div>
        </div>
        <div className="grid-container custom-grid">
          <div className="grid-item grid-span-8 grid-row-span-2">
            {/* Watch tee */}
            <CustomCollection highResImages={ true } products={ products } productIds={ [248433400] } USE_COLLECTIONS={ USE_COLLECTIONS } /> {/* US */}
          </div>
          <div className="grid-item grid-span-4">
            {/* Watch long sleeve tee */}
            <CustomCollection highResImages={ true } products={ products } productIds={ [248433399] } USE_COLLECTIONS={ USE_COLLECTIONS } />
          </div>
          <div className="grid-item grid-span-4">
            {/* Watch mug */}
            <CustomCollection highResImages={ true } products={ products } productIds={ [252259526] } USE_COLLECTIONS={ USE_COLLECTIONS } /> {/* EU */}
          </div>
        </div>
        <div className="tile-heading">
          {isFetching && !products?.length ? (
            <Skeleton styles={ { height: 30, width: '45%' } } />
          ) : (
            <img src="/assets/bttf/collection-header.webp" alt="Collection header" />
          )}
        </div>
        <div className="grid-container custom-grid">
          <div className="grid-item grid-span-8 grid-row-span-2">
            {/* Clock silhouette */}
            <CustomCollection products={ products } productIds={ [248433392] } USE_COLLECTIONS={ USE_COLLECTIONS } /> {/* US */}
          </div>
          <div className="grid-item grid-span-4">
            {/* Nobody calls me yellow tee */}
            <CustomCollection products={ products } productIds={ [248433396] } USE_COLLECTIONS={ USE_COLLECTIONS } />
          </div>
          <div className="grid-item grid-span-4">
            {/* RockNRoll tee */}
            <CustomCollection highResImages={ true } products={ products } productIds={ [248615929] } USE_COLLECTIONS={ USE_COLLECTIONS } /> {/* US */}
          </div>
        </div>
        <div className="grid-container custom-grid">
          <div className="grid-item grid-span-6">
            {/* Mr fusion hoodie */}
            <CustomCollection highResImages={ true } products={ products } productIds={ [248433398] } USE_COLLECTIONS={ USE_COLLECTIONS } /> {/* US */}
          </div>
          <div className="grid-item grid-span-6">
            {/* Mr fusion bottle */}
            <CustomCollection highResImages={ true } products={ products } productIds={ [248433397] } USE_COLLECTIONS={ USE_COLLECTIONS } /> {/* US */}
          </div>
        </div>
      </div>
      <div className="slider-container">
        <Slider { ...settings }>
          { SldierImages.map(slide => (
            <div key={ slide }>
              <img width="100%" alt="Back to the future " src={ slide?.src } />
            </div>
          )) }
        </Slider>
      </div>
      <div
        style={ {
          maxWidth: '1440px',
          margin: 'auto'
        } }
      >
        <div className="grid-container custom-grid">
          <div className="grid-item grid-span-8 grid-row-span-2">
            {/* Silence Earthling Hoodie */}
            <CustomCollection highResImages={ true } products={ products } productIds={ [248433401] } USE_COLLECTIONS={ USE_COLLECTIONS } /> {/* US */}
          </div>
          <div className="grid-item grid-span-4">
            {/* Silence Earthling Tee */}
            <CustomCollection highResImages={ true } products={ products } productIds={ [248433402] } USE_COLLECTIONS={ USE_COLLECTIONS } /> {/* US */}
          </div>
          <div className="grid-item grid-span-4">
            {/* 88 MPH long sleeve */}
            <CustomCollection highResImages={ true } products={ products } productIds={ [248435248] } USE_COLLECTIONS={ USE_COLLECTIONS } /> {/* US */}
          </div>
          <div className="grid-item grid-span-6">
            {/* Great scott hoodie */}
            <CustomCollection highResImages={ true } products={ products } productIds={ [248433403] } USE_COLLECTIONS={ USE_COLLECTIONS } /> {/* US */}
          </div>
          <div className="grid-item grid-span-6">
            {/* Great scott tee */}
            <CustomCollection highResImages={ true } products={ products } productIds={ [248433404] } USE_COLLECTIONS={ USE_COLLECTIONS } /> {/* US */}
          </div>
          <div className="grid-item grid-span-6">
            {/* The future is unwritten tee */}
            <CustomCollection highResImages={ true } products={ products } productIds={ [248433394] } USE_COLLECTIONS={ USE_COLLECTIONS } /> {/* US */}
          </div>
          <div className="grid-item grid-span-6">
            {/* The future is unwritten hoodie */}
            <CustomCollection highResImages={ true } products={ products } productIds={ [248433393] } USE_COLLECTIONS={ USE_COLLECTIONS } /> {/* US */}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const { string, shape, bool } = propTypes;

ProductFeed.propTypes = {
  title: string,
  localizationData: shape({}).isRequired,
  storeName: string,
  previewMode: bool.isRequired,
  storeListings: shape({}).isRequired
};

ProductFeed.defaultProps = {
  title: null,
  storeName: ''
};

const mapStateToProps = state => ({
  localizationData: state.localizationData,
  storeName: state.stores.name,
  previewMode: get(state, 'themeData.previewMode'),
  storeListings: get(state, 'storeListings')
});

export default connect(mapStateToProps)(ThemeWrapper(ProductFeed, ['content.productList', 'styles.productList', 'layout.productList']));
